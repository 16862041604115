import __Base from '@components/Base';
import uniqid from '@utils/lodash/uniqid';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import DanimInput from './DanimInput';
class GoogleInput extends __Base {
  constructor(props) {
    super(props);
    this.id = uniqid();
  }
  componentDidMount = () => {
    gWatch.input(this);
  };
  setup = () => {
    const {
      onChange,
      types
    } = this.props;
    if (!_.get(window, this.id, null)) {
      if (this.input && this.input.root) window[this.id] = new google.maps.places.Autocomplete(this.input.root.getElementsByTagName('input')[0], {
        types
      });
      window[this.id]?.addListener('place_changed', () => onChange(window[this.id].getPlace()));
      // window[this.id]?.addListener(
      //     'place_changed',
      //     () => cl(JSON.stringify(window[this.id].getPlace()))
      // );
    }
  };
  render = () => {
    const {
      append,
      customAttributes,
      helper,
      error,
      warning,
      label,
      mountFocus,
      name,
      onInputChange,
      placeholder,
      prepend,
      value
    } = this.props;
    const attr = customAttributes;
    attr.root.className = `googleInput ${_.get(attr, 'root.className', '')}`;
    return <DanimInput ref={obj => this.input = obj} customAttributes={attr} label={label} helper={helper} error={error} warning={warning} value={value} placeholder={placeholder} name={name} mountFocus={mountFocus} onChange={onInputChange} append={append} prepend={prepend} />;
  };
}
GoogleInput.defaultProps = {
  append: null,
  caption: '',
  customAttributes: {},
  error: null,
  helper: null,
  label: '',
  mountFocus: false,
  name: '',
  onChange: place => null,
  onInputChange: ev => null,
  placeholder: '',
  prepend: null,
  types: ['geocode'],
  value: '',
  warning: null
};
GoogleInput.propTypes = {
  // optional
  append: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  customAttributes: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node]),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  prepend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mountFocus: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  types: PropTypes.array,
  value: PropTypes.string,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.node])
};
export default GoogleInput;