import __Base from '@components/bridge/bridge/modal/abs/Content';
import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '@components/office/front-office/user/CheckoutForm';
import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js/pure';
import { apiRequest } from '@services/http/client';
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);
class Checkout extends __Base {
  static domain = 'user';
  static type = 'checkout';
  static defaultPropsFactory = () => null;
  static defaultTitle = () => {
    return state => tr('front.modal.checkout.title.default');
  };
  static loading = () => modal.MODAL_LOADING_ALWAYS;
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      infoOnPrices: null,
      infoOnPacks: null,
      loaded: true
    };
  }
  componentDidMount = () => {
    const {
      appuser
    } = this.props;
    const body = {
      query: `
            query {
                user (id: "${appuser.id}") {
                    organization {
                        thirdPaymentMethods {
                            id
                            card {
                                country
                                expMonth
                                expYear
                                last4
                                brand
                                fingerprint
                            }
                            sepaDebit {
                                country
                                last4
                                fingerprint
                            }
                        }
                    }
                }
                thirdPrices { 
                    id
                    unitAmount
                    monthly
                    nickname
                }
                thirdProducts { 
                    id
                    name
                    licensesCount
                }
            }
        `
    };
    apiRequest(body.query).then(data => {
      this.updateState({
        user: _.get(data, 'data.user'),
        infoOnPrices: _.get(data, 'data.thirdPrices'),
        infoOnPacks: _.get(data, 'data.thirdProducts')
      });
    }).catch(() => {
      addFlash({
        type: 'error'
      });
      modal.close();
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.user && this.state.loaded) {
      if (prevState.user !== this.state.user) {
        setTimeout(() => {
          setTimeout(() => modal.beLoading(false), 400);
        }, 400);
      }
    }
  };
  render = () => {
    const {
      onSuccess,
      showToBeLicensedSelect,
      blockExit,
      appuser
    } = this.props;
    const {
      infoOnPrices,
      infoOnPacks,
      user
    } = this.state;
    const currentDealIsFirst = !_.get(appuser, 'organization.plan.deals.0.thirdSubscriptionId');
    const maxUsersToBeLicensed = infoOnPacks?.find(pack => pack.id === _.get(appuser, `organization.plan.deals.${currentDealIsFirst ? 0 : 1}.thirdProductId`))?.licensesCount || 1;
    return <div className='-content'>
                <Elements stripe={stripePromise} options={{
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Mulish'
        }],
        locale: 'fr'
      }}>
                    <CheckoutForm appuser={appuser} paymentMethods={_.get(user, 'organization.thirdPaymentMethods') || []} infoOnPrices={infoOnPrices} infoOnPacks={infoOnPacks} blockExit={blockExit} showToBeLicensedSelect={showToBeLicensedSelect} maxUsersToBeLicensed={maxUsersToBeLicensed} onSuccess={onSuccess} />
                </Elements>
            </div>;
  };
}
Checkout.defaultProps = {
  blockExit: false,
  onSuccess: () => null,
  showToBeLicensedSelect: false
};
Checkout.propTypes = {
  blockExit: PropTypes.bool,
  onSuccess: PropTypes.func,
  showToBeLicensedSelect: PropTypes.bool
};
export default Checkout;