import __Base from '@components/bridge/bridge/modal/abs/Content';
import React from 'react';
import PropTypes from 'prop-types';
import DanimInput from '@components/bridge/bridge/DanimInput';
import DanimSelect from '@components/bridge/bridge/DanimSelect';
import WaitingButton from '@components/bridge/bridge/WaitingButton';
import Or from '@components/bridge/bridge/Or';
import _ from 'lodash';
import { apiRequest, axios } from '@services/http/client';
class Config extends __Base {
  static domain = 'template';
  static type = 'config';
  static defaultPropsFactory = () => null;
  static defaultTitle = () => {
    return state => tr('front.modal.config.title', {}, 'front_office-template');
  };
  static loading = () => modal.MODAL_LOADING_ALWAYS;
  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: '',
        changed: false,
        error: null
      },
      template: null,
      workspaces: {
        all: [],
        initial: [],
        selected: [],
        changed: true
      },
      submit: {
        validating: false,
        deleting: false
      }
    };
  }
  componentDidMount = () => {
    const {
      templateId
    } = this.props;
    apiRequest(`
        query {
            template (id: "${templateId}") {
                id
                name
                depth
                organization {
                    id
                    workspaces (first: -1) {
                        edges {
                            node {
                                id
                                name
                                organizationId
                            }
                        }
                    }
                }
                children (first: -1 private: null) {
                    edges {
                        node {
                            id
                            workspace {
                                id
                                name
                                organizationId
                            }
                        }
                    }
                }
            }
        }
    `).then(data => {
      const {
        template
      } = data.data;
      this.updateState(draft => {
        draft.name.value = _.get(template, 'name') || '';
        draft.template = template;
        draft.workspaces.all = _.compact(_.map(_.get(template, 'organization.workspaces.edges') || [], edge => _.get(edge, 'node')));
        draft.workspaces.initial = _.compact(_.map(_.get(template, 'children.edges') || [], edge => ({
          templateId: _.get(edge, 'node.id'),
          ..._.get(edge, 'node.workspace')
        })));
        draft.workspaces.selected = _.map(_.cloneDeep(draft.workspaces.initial), workspace => {
          delete workspace.templateId;
          return workspace;
        });
      });
      modal.beLoading(false);
    });
  };
  handleInputChange = ev => {
    let target = ev.target;
    let value = _.get(target, 'type') === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.updateState(draft => {
      draft[name].value = value;
      draft[name].changed = true;
    });
  };
  handleRemove = () => {
    const {
      onRemove,
      templateId
    } = this.props;
    this.updateState(draft => {
      draft.submit.deleting = true;
    }, async () => {
      const body = {
        templateId,
        force: true
      };
      const underWhitelabel = !window.areWeUnderDanimHost();
      const options = {
        method: 'POST',
        url: gen('write/template/remove'),
        data: body,
        underWhitelabel
      };
      await axios(options, {
        then: data => {
          addFlash({
            type: 'success',
            message: tr('front.modal.config.ajax.remove.success', {}, 'front_office-template')
          });
          modal.close();
          onRemove(templateId);
        },
        catch: () => {
          addFlash({
            type: 'warning',
            message: tr(`front.modal.deploy.ajax.error.${error.name === 'StillVideosToHandle' ? 'still_videos' : 'default'}`, {}, 'bridge-bot')
          });
        },
        finally: () => {
          this.updateState(draft => {
            draft.submit.deleting = false;
          });
        }
      });
    });
  };
  handleSubmit = () => {
    const {
      onNameChange,
      templateId
    } = this.props;
    if (this.validateForm()) {
      let body = {
        commands: []
      };
      if (this.state.name.changed) {
        body.commands.push({
          name: 'changeTemplate',
          payload: {
            templateId,
            patch: {
              ...(this.state.name.changed && {
                name: this.state.name.value
              })
            }
          }
        });
      }
      if (this.state.workspaces.changed) {
        const leaved = _.differenceBy(this.state.workspaces.initial, this.state.workspaces.selected, 'id');
        const entered = _.differenceBy(this.state.workspaces.selected, this.state.workspaces.initial, 'id');
        if (leaved.length) {
          _.each(leaved, workspace => {
            body.commands.push({
              name: 'removeTemplate',
              payload: {
                templateId: workspace.templateId,
                force: true
              }
            });
          });
        }
        if (entered.length) {
          _.each(entered, workspace => {
            body.commands.push({
              name: 'createTemplate',
              payload: {
                state: 'VALID',
                organizationId: workspace.organizationId,
                workspaceId: workspace.id,
                parentIds: [templateId]
              }
            });
          });
        }
      }
      if (body.commands.length) {
        this.updateState(draft => {
          draft.submit.validating = true;
        }, async () => {
          const underWhitelabel = !window.areWeUnderDanimHost();
          const options = {
            method: 'POST',
            url: gen('write'),
            data: body,
            underWhitelabel
          };
          await axios(options, {
            then: data => {
              addFlash({
                type: 'success',
                message: tr('front.modal.config.ajax.update.success', {}, 'front_office-template')
              });
              if (this.state.name.changed) onNameChange(templateId, this.state.name.value);
              modal.close();
            },
            catch: () => {
              addFlash({
                type: 'warning',
                message: tr('front.modal.config.ajax.update.error', {}, 'front_office-template')
              });
            },
            finally: () => {
              this.updateState(draft => {
                draft.submit.validating = false;
              });
            }
          });
        });
      }
    }
  };
  handleWorkspacesChange = values => {
    this.updateState(draft => {
      draft.workspaces.selected = values;
      draft.workspaces.changed = true;
    });
  };

  /**
   * Returns bool: true if no validation error was detected
   */
  validateForm = () => {
    let noProblem = true;
    let filled = {};

    // name
    //      filled?
    filled.name = !!this.state.name.value;
    this.updateState(draft => {
      draft.name.error = filled.name ? null : 'front.modal.config.form.name.error.empty';
    });
    noProblem *= filled.name;
    return noProblem;
  };
  render = () => {
    const {
      workspace
    } = this.props;
    return <div className='-content'>
                <DanimInput customAttributes={{
        root: {
          className: '-name'
        }
      }} name='name' placeholder={tr('front.modal.config.form.name.placeholder', {}, 'front_office-template')} label={tr('front.modal.config.form.name.label', {}, 'front_office-template')} value={this.state.name.value} type='text' onChange={this.handleInputChange} error={tr(...(this.state.name.error || []))} />
                {!workspace && <DanimSelect customAttributes={{
        root: {
          className: '-workspaces'
        }
      }} allable label={tr('front.modal.config.form.workspaces.label', {}, 'front_office-template')} value={this.state.workspaces.selected} onChange={this.handleWorkspacesChange} isMulti options={_.map(this.state.workspaces.all, workspace => ({
        value: workspace,
        label: _.get(workspace, 'name') || 'Anonymous'
      }))} />}
                <WaitingButton customAttributes={{
        root: {
          className: '-submit'
        }
      }} onClick={this.handleSubmit} type='button' waiting={this.state.submit.validating} text={tr('front.modal.config.form.submit', {}, 'front_office-template')} />
                <Or text={tr('front.modal.config.or', {}, 'front_office-template')} />
                <WaitingButton customAttributes={{
        root: {
          className: '-remove'
        }
      }} onClick={this.handleRemove} type='button' mood='danger' waiting={this.state.submit.deleting} text={tr('front.modal.config.form.remove.text', {}, 'front_office-template')} />
                <div className='-beware' dangerouslySetInnerHTML={{
        __html: tr(`front.modal.config.form.remove.beware.l${workspace ? '2' : '1'}`, {}, 'front_office-template')
      }} />
            </div>;
  };
}
Config.defaultProps = {
  onNameChange: (templateId, newName) => null,
  onRemove: templateId => null
};
Config.propTypes = {
  // required
  templateId: PropTypes.string.isRequired,
  // optional
  onRemove: PropTypes.func
};
export default Config;