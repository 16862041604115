import __Base from '@components/Base';
import _ from 'lodash';
import React from 'react';
import { apiRequest, axios } from '@services/http/client';
import PropTypes from 'prop-types';
import Snippet from '@components/office/front-office/template/Snippet';
import SnippetWaiter from '@components/office/front-office/template/SnippetWaiter';
import Checkout from '@components/office/front-office/user/modal/content/Checkout';
import Config from '@components/office/front-office/template/modal/content/Config';
import moment from 'moment/moment';
class TemplateLibrary extends __Base {
  constructor(props) {
    super(props);
    this.fetching = false;
    this.state = {
      list: {
        organization: {
          items: [],
          totalCount: parseInt(_.get(props.templates, 'totalCount')),
          hasNextPage: !!_.get(props.templates, 'totalCount')
        },
        library: {
          items: [],
          totalCount: parseInt(_.get(props.sharedTemplates, 'totalCount')),
          hasNextPage: !!_.get(props.sharedTemplates, 'totalCount')
        }
      },
      loading: false,
      mute: true
    };
  }
  static openCheckoutModal = (blockExit = true, appuser) => {
    modal.set(Checkout, tr('front.modal.checkout.title.licenses_needed_to_continue'), {
      appuser,
      showToBeLicensedSelect: true,
      blockExit,
      onSuccess: async (appuser, user) => {
        addFlash({
          type: 'success'
        });
        modal.close();
      }
    }, true, () => {
      modal.open();
      if (blockExit) modal.block();
    });
  };
  static closeCheckoutModal = appuser => {
    modal.set(Checkout, tr('front.modal.checkout.title.licenses_needed_to_continue'), {
      appuser,
      showToBeLicensedSelect: true,
      onSuccess: async (appuser, user) => {
        addFlash({
          type: 'success'
        });
        modal.close();
      }
    }, true, () => {
      modal.close();
    });
  };
  static potentiallyBlockUnlicensedUser = async (appuser, router) => {
    if (!appuser) return;
    const currentlyHasAtLeastOneOpenInvoice = appuser.organization?.currentlyHasAtLeastOneOpenInvoice;
    const currentlyHasAtLeastOneProcessingPaymentIntent = appuser.organization?.currentlyHasAtLeastOneProcessingPaymentIntent;
    if (currentlyHasAtLeastOneOpenInvoice && (
    // A license has successfully been granted, at least once (e.g. processing SEPA, successful card...).
    // That condition therefore means: not a failure on very first payment, potentially abandoned and instantaneous (i.e. no processing phase).
    // E.g. Bob wanted to start a payments cycle, and payed with a card having insufficient funds: he must be able to retry with another one, resulting in a new subscription.
    // @commentid e2f27f69_626c_42b9_a2e1_e6913294e253
    _.get(appuser, 'organization.plan.traits.maxLicensedWithPeriodicCloudyMembersCount.dealPeak') > 0 ||
    // Additional safety, just in case
    appuser?.hasOrganizationPlanThirdlyActiveDeal())) {
      const endAt = _.get(appuser, 'organization.plan.deals[0].thirdSubscriptionEndAt');
      modal.set((await import('@components/office/front-office/user/modal/content/PaymentIntentsStatus')).default, tr('front.modal.payment_intents_status.title', {
        status: tr('front.modal.payment_intents_status.status.failed')
      }, 'front_office-user'), {
        appuser,
        router,
        currentlyHasAtLeastOneOpenInvoice,
        currentlyHasAtLeastOneProcessingPaymentIntent
      }, true, () => {
        modal.open();
        if (endAt === null || moment(endAt) < moment(new Date())) modal.block();
      });
      return;
    }
    if (!appuser.isUnderTrial() && !appuser.isUnderExternalBilling() && !_.get(appuser, 'account.license')) {
      if (appuser.canMakePayments() && !appuser.doesOrganizationStillHaveUnassignedLicensesLeft()) TemplateLibrary.openCheckoutModal(true, appuser);else {
        modal.set((await import('@components/office/front-office/user/modal/content/LicenseNeededAmongTheAvailableOnes')).default, null, {
          appuser
        }, true, () => modal.open() & modal.block());
      }
    } else {
      TemplateLibrary.closeCheckoutModal(appuser);
    }
  };
  static potentiallyBlockPausedUser = async (appuser, router) => {
    if (!appuser) return;
    const currentlyHasPaused = appuser.organization?.plan.deals.find(deal => deal.pause !== null)?.pause;
    const thirdSubscriptionId = appuser.organization?.plan.deals[0]?.thirdSubscriptionId;
    const endAt = currentlyHasPaused?.endAt;
    if (currentlyHasPaused && thirdSubscriptionId) {
      modal.set((await import('@components/office/front-office/user/modal/content/PlanPause')).default, tr('front.modal.plan_pause.title'), {
        appuser,
        router,
        endAt
      }, true, () => {
        modal.open();
        if (moment(endAt) > moment(new Date())) modal.block();
      });
    }
  };
  componentDidMount = () => {
    TemplateLibrary.potentiallyBlockUnlicensedUser(this.props.appuser, this.props.router);
    TemplateLibrary.potentiallyBlockPausedUser(this.props.appuser, this.props.router);
    if (this.state.list.organization.hasNextPage || this.state.list.library.hasNextPage) this.fetch();
  };
  componentDidUpdate = prevProps => {
    const {
      templates,
      sharedTemplates
    } = this.props;
    if (templates !== prevProps.templates) {
      this.updateState(draft => {
        draft.list.organization.items = [];
        draft.list.organization.totalCount = templates.totalCount;
      }, () => {
        if (this.state.list.organization.totalCount) this.fetch();
      });
    }
    if (sharedTemplates !== prevProps.sharedTemplates) {
      this.updateState(draft => {
        draft.list.library.items = [];
        draft.list.library.totalCount = sharedTemplates.totalCount;
      }, () => {
        if (this.state.list.library.totalCount) this.fetch();
      });
    }
  };
  fetch = () => {
    if (this.fetching) return; // a fetch is already being runned

    this.updateState(draft => {
      draft.loading = true;
    }, async () => {
      const {
        fetchQuery
      } = this.props;
      this.fetching = true;
      const promises = ['organization', 'library'].map(async requestId => {
        const data = await apiRequest(fetchQuery(requestId, this.state, this.props)).catch(console.error);
        return {
          requestId,
          data
        };
      });
      Promise.all(promises).then(result => {
        result.forEach(res => {
          if (res.data) {
            const {
              data,
              requestId
            } = res;
            const domain = `homeTemplates${requestId.charAt(0).toUpperCase() + requestId.slice(1)}`;
            if (data.errors) {
              this.updateState(draft => {
                draft.list[requestId].items = [];
                draft.list[requestId].totalCount = 0;
                draft.list[requestId].hasNextPage = 0;
              });
            } else {
              this.updateState(draft => {
                draft.list[requestId].items = _.uniqBy(_.concat(draft.list[requestId].items, _.compact(_.get(data, `data.${domain}.edges`) || [])), 'node.id');
                draft.list[requestId].totalCount = _.get(data, `data.${domain}.totalCount`);
                draft.list[requestId].hasNextPage = _.get(data, `data.${domain}.pageInfo.hasNextPage`);
              });
            }
          }
        });
      }).finally(() => {
        this.updateState({
          loading: false
        });
        this.fetching = false;
      });
    });
  };
  handleConfigClick = templateId => {
    modal.set(Config, null, {
      templateId,
      onNameChange: (templateId, newName) => {
        this.updateState(draft => {
          _.each(draft.list.organization.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.name = newName;
          });
          _.each(draft.list.library.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.name = newName;
          });
        });
      },
      onRemove: templateId => {
        this.updateState(draft => {
          _.remove(draft.list.organization.items, item => {
            if (_.get(item, 'node.id') === templateId) {
              draft.list.organization.totalCount = Math.max(0, draft.list.organization.totalCount - 1);
              return _.get(item, 'node.id') === templateId;
            }
          });
          _.remove(draft.list.library.items, item => {
            if (_.get(item, 'node.id') === templateId) {
              draft.list.library.totalCount = Math.max(0, draft.list.library.totalCount - 1);
              return _.get(item, 'node.id') === templateId;
            }
          });
        });
      }
    }, true);
  };
  handleLockClick = async templateId => {
    const body = {
      templateId,
      patch: {
        private: {
          hereditary: true,
          selfIncluded: false
        }
      }
    };
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/template/change'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: data => {
        addFlash({
          type: 'success',
          message: tr('front.library.ajax.lock.success', {}, 'front_office-template')
        });
        this.updateState(draft => {
          _.each(draft.list.organization.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.private = {
              hereditary: true,
              selfIncluded: false
            };
          });
          _.each(draft.list.library.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.private = {
              hereditary: true,
              selfIncluded: false
            };
          });
        });
      },
      catch: () => {
        addFlash({
          type: 'warning',
          message: tr('front.library.ajax.lock.error', {}, 'front_office-template')
        });
      }
    });
  };
  handleMoreClick = () => this.fetch();
  handleMouseOff = templateId => {
    this.updateState(draft => {
      _.each(draft.list.organization.items, item => {
        if (_.get(item, 'node.id') === templateId) item.playing = false;
      });
      _.each(draft.list.library.items, item => {
        if (_.get(item, 'node.id') === templateId) item.playing = false;
      });
    });
  };
  handleMouseOn = templateId => {
    this.updateState(draft => {
      _.each(draft.list.organization.items, item => {
        item.playing = _.get(item, 'node.id') === templateId;
      });
      _.each(draft.list.library.items, item => {
        item.playing = _.get(item, 'node.id') === templateId;
      });
    });
  };
  handleRemoveClick = async templateId => {
    const body = {
      templateId,
      force: true
    };
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/template/remove'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: data => {
        addFlash({
          type: 'success',
          message: tr('front.library.ajax.remove.success', {}, 'front_office-template')
        });
        this.updateState(draft => {
          _.remove(draft.list.organization.items, item => {
            if (_.get(item, 'node.id') === templateId) {
              draft.list.organization.totalCount = Math.max(0, draft.list.organization.totalCount - 1);
              return _.get(item, 'node.id') === templateId;
            }
          });
          _.remove(draft.list.library.items, item => {
            if (_.get(item, 'node.id') === templateId) {
              draft.list.library.totalCount = Math.max(0, draft.list.library.totalCount - 1);
              return _.get(item, 'node.id') === templateId;
            }
          });
        });
      },
      catch: error => {
        addFlash({
          type: 'warning',
          message: tr(`
                                        front.library.ajax.remove.error.$;
                                        {
                                            error.name === 'StillVideosToHandle' ? 'still_videos' : 'default';
                                        }
                                        `, {}, 'front_office-template')
        });
      }
    });
  };
  handleUnlockClick = async templateId => {
    const body = {
      templateId,
      patch: {
        private: null
      }
    };
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/template/change'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: data => {
        addFlash({
          type: 'success',
          message: tr('front.library.ajax.unlock.success', {}, 'front_office-template')
        });
        this.updateState(draft => {
          _.each(draft.list.organization.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.private = null;
          });
          _.each(draft.list.library.items, item => {
            if (_.get(item, 'node.id') === templateId) item.node.private = null;
          });
        });
      },
      catch: () => {
        addFlash({
          type: 'warning',
          message: tr('front.library.ajax.unlock.error', {}, 'front_office-template')
        });
      }
    });
  };
  handleVolumeClick = () => {
    this.updateState(draft => {
      draft.mute = !draft.mute;
    });
  };
  render = () => {
    const {
      first,
      isInOrganization,
      sharedName,
      appuser,
      workspace
    } = this.props;
    const items = _.uniqBy(_.concat(this.state.list.organization.items, this.state.list.library.items), 'node.id');
    const nbLoading = Math.min(first, parseInt(this.state.list.organization.totalCount + this.state.list.library.totalCount - (items || []).length));
    return <section className='template:library'>
                <div className='-templates' data-test-id='templates-list-container'>
                    {(items.length === 0 && !this.state.loading && nbLoading === 0) === true && <div className='-noItems'>{tr('front.library.no_items', {}, 'front_office-template')}</div>}
                    {items.map(item => {
          return <React.Fragment key={_.get(item, 'node.id')}>
                                <Snippet id={_.get(item, 'node.id')} appuser={appuser} workspace={workspace} template={item.node} mute={this.state.mute} playing={!!item.playing} onConfigClick={this.handleConfigClick} onLockClick={this.handleLockClick} onMouseOff={this.handleMouseOff} onMouseOn={this.handleMouseOn} onRemoveClick={this.handleRemoveClick} onUnlockClick={this.handleUnlockClick} onVolumeClick={this.handleVolumeClick} />
                            </React.Fragment>;
        })}
                    {(this.state.loading && nbLoading > 0) === true && [...Array(nbLoading).keys()].map(n => <SnippetWaiter key={n} />)}
                    {((this.state.list.organization.hasNextPage || this.state.list.library.hasNextPage) && !this.state.loading) === true && <span className='-loadMore' onClick={this.handleMoreClick}>
                            {tr('front.library.load_more', {}, 'front_office-template')}
                        </span>}
                </div>
            </section>;
  };
}
TemplateLibrary.defaultProps = {
  first: 16,
  displayFooter: true,
  isInOrganization: false,
  sharedName: null
};
TemplateLibrary.propTypes = {
  // required
  sharedTemplates: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // tab will not appear if false
  templates: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // tab will not appear if false
  fetchQuery: PropTypes.func,
  // optional
  displayFooter: PropTypes.bool,
  first: PropTypes.number,
  isInOrganization: PropTypes.bool,
  sharedName: PropTypes.string
};
export default TemplateLibrary;