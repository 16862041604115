function queryOrganizationTeam(id) {
    return id
        ? `query queryOrganizationTeam {
                organizationPayloadTeamUser:
                    user (id: "${id}") {
                        id
                        organization {
                            id
                            name
                            members (first: -1) {
                                edges {
                                    node {
                                        id
                                        account {
                                            email
                                            activated
                                            locked {
                                                authorId
                                                at
                                            }
                                            lastActivationPoke {
                                                at
                                            }
                                            license {
                                                id
                                                cloudy
                                                periodic
                                            }
                                        }
                                        profile {
                                            names {
                                                both
                                            }
                                            portrait {
                                                src (absolute: false)
                                            }
                                            organizationBadges {
                                                workspaceId
                                                badge
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
            }
        `
        : null;
}

const queryOrganizationTeamMock = () => 'FrontOrganizationTeam';

const queryFrontOrganizationTeamApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryOrganizationTeamMock : queryOrganizationTeam;

export default queryFrontOrganizationTeamApi;
