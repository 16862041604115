import React from 'react';
import * as _ from 'lodash';
import moment from 'moment/moment';
const DealSummary = React.memo(function DealSummaryPage({
  offeredMonthLicensePacksTotalCount,
  deal,
  nextDeal,
  // Why not making it optional and taking the settled amounts in thirdPriceTable?
  // Because here, we may be about to charge someone (e.g. checkout modal), and this a sensitive operation: we must be sure of the prices
  // We prefer a difference between announced prices outside and inside the checkout, rather than homogeneous numbers which may be wrong
  // That is why we force the developer to know what s.he does, by making this required.
  // Ideally, this should be the data retrieved via a Stripe API call (e.g. indirectly, with GQL)
  // But, we may also pass an empty object to tell "do the computation with the thirdPriceTable constants, that will do" (e.g. on the Back Office)
  // No default anyway (i.e. no "infoOnPrices = {}")
  infoOnPrices,
  infoOnPacks
}) {
  const {
    preTaxAmountWithoutCoupon,
    preTaxAmountWithCoupon
  } = window.computeLicensePriceBasedOnThirdInfo(infoOnPrices, infoOnPacks, deal);
  const {
    preTaxAmountWithoutCoupon: preTaxAmountWithoutCouponForNextDeal,
    preTaxAmountWithCoupon: preTaxAmountWithCouponForNextDeal
  } = nextDeal ? window.computeLicensePriceBasedOnThirdInfo(infoOnPrices, infoOnPacks, nextDeal) : {
    preTaxAmountWithoutCoupon: null
  };
  const currentProductName = infoOnPacks?.find(p => p.id === _.get(deal, 'thirdProductId'))?.name || null;
  const nextProductName = infoOnPacks?.find(p => p.id === _.get(nextDeal, 'thirdProductId'))?.name || null;
  let monthlyPaid = _.get(deal, 'monthlyPaid');
  if (!_.isBoolean(monthlyPaid)) monthlyPaid = true;
  let monthlyPaidNextDeal = _.get(nextDeal, 'monthlyPaid');
  if (!_.isBoolean(monthlyPaidNextDeal)) monthlyPaidNextDeal = true;
  let coupon = _.get(deal, 'coupon');
  if (!coupon) coupon = {
    amount: 0,
    percentage: true
  };
  let nextCoupon = _.get(nextDeal, 'coupon');
  const thirdlyInitiated = !!_.get(deal, 'thirdSubscriptionId');
  const monthsCount = _.get(deal, 'monthsCount') || 12;
  const billingPeriodEndAt = _.get(deal, 'thirdSubscriptionBillingPeriodEndAt') || moment().add(1, monthlyPaid ? 'M' : 'Y').format(DATETIME_FORMAT);
  const endAt = _.get(deal, 'thirdSubscriptionEndAt') || moment().add(monthsCount, 'M').format(DATETIME_FORMAT);
  const doesIt = {
    show: {
      noticeAbout: {
        paymentRightNow: () => !!(!monthlyPaid && monthsCount === 12 &&
        // One shot
        !doesIt.show.noticeAbout.offeredMonthLicensePacks()
        // Nothing to say about the pending payment is it is free at start...
        ),
        offeredMonthLicensePacks: () => !!(offeredMonthLicensePacksTotalCount && !thirdlyInitiated // No need to bother user with this topic if it is already engaged.
        )
      }
    }
  };
  return <div className='dealSummary'>
            {infoOnPrices && infoOnPacks && <span>
                    {currentProductName ? `• ${currentProductName} : ` : nextProductName ? `• ${nextProductName} : ` : null}
                    {infoOnPrices && <strong>
                            {/* {thirdlyInitiated ? '+ ' : ''} */}
                            {new Intl.NumberFormat('fr', {
          style: 'currency',
          currency: 'EUR'
        }).format(preTaxAmountWithCoupon)}{' '}
                            {'HT / '}
                            {tr(`front.deal_summary.price.time_unit.${monthlyPaid ? 'month' : 'year'}.singular`, {}, 'bridge-organization')}
                        </strong>}
                </span>}
            <small>
                <span dangerouslySetInnerHTML={{
        __html: tr(`front.deal_summary.subscription.you_are_engaged.${thirdlyInitiated ? 'prorated' : 'default'}`, {
          duration: `${monthsCount / 12} ${tr(`front.deal_summary.price.time_unit.year.${monthsCount > 12 ? 'plural' : 'singular'}`, {}, 'bridge-organization')}`,
          endAt: moment(endAt).local().locale('fr').format('ll')
        }, 'bridge-organization')
      }} />{' '}
                <span dangerouslySetInnerHTML={{
        __html: tr('front.deal_summary.subscription.you_are_engaged.suffix.base', {
          nextPrice: new Intl.NumberFormat('fr', {
            style: 'currency',
            currency: 'EUR'
          }).format(nextCoupon ? preTaxAmountWithCouponForNextDeal : preTaxAmountWithCoupon),
          taxable: 'HT',
          nextPeriodPrice: tr(`front.deal_summary.price.time_unit.${monthlyPaidNextDeal ? 'month' : 'year'}.singular`)
        })
      }} />
            </small>
            {doesIt.show.noticeAbout.paymentRightNow() && <small dangerouslySetInnerHTML={{
      __html: tr('front.deal_summary.subscription.you_will_pay_now', {
        billingPeriodEndAt: moment(billingPeriodEndAt).local().locale('fr').format('ll')
      }, 'bridge-organization')
    }} />}
            {doesIt.show.noticeAbout.offeredMonthLicensePacks() && <small dangerouslySetInnerHTML={{
      __html: offeredMonthLicensePacksTotalCount === 1 ? tr('front.deal_summary.subscription.offered_months.one', {
        count: offeredMonthLicensePacksTotalCount
      }, 'bridge-organization') : tr('front.deal_summary.subscription.offered_months.other', {
        count: offeredMonthLicensePacksTotalCount
      }, 'bridge-organization')
    }} />}
            <small>
                {tr('front.deal_summary.subscription.adblock')}
                <span className='-chatbot' onClick={() => window.openChat()}>
                    {tr('front.deal_summary.subscription.contact_support')}
                </span>{' '}
                support@danim.com
            </small>
        </div>;
});
export default DealSummary;