import React, { useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import DanimInput from '@components/bridge/bridge/DanimInput';
import GoogleInput from '@components/bridge/bridge/GoogleInput';
import pascalCase from '@utils/lodash/pascalCase';
import DanimSelect from '@components/bridge/bridge/DanimSelect';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import WaitingButton from '@components/bridge/bridge/WaitingButton';
import md5 from 'md5';
import DealSummary from '@components/bridge/organization/DealSummary';
import Link from 'next/link';
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import isEmail from 'validator/lib/isEmail';
import dynamic from 'next/dynamic';
import { mutate } from 'swr';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import { apiRequest, axios } from '@services/http/client';
import queryFrontOrganizationTeamApi from '@queries/api/office/front-office/organization/queryOrganizationTeam';
import Factory from '@components/bridge/bridge/immutable/factory';
import * as Sentry from '@sentry/nextjs';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
const CheckoutForm = React.memo(function Checkout({
  appuser,
  infoOnPrices,
  infoOnPacks,
  onSuccess = () => null,
  paymentMethods = [],
  maxUsersToBeLicensed,
  withReferentInfo = false,
  showToBeLicensedSelect = false,
  blockExit = false
}) {
  const stripe = useStripe();
  const elements = useElements();
  const offeredMonthLicensePacksTotalCount = _.get(appuser, 'organization.plan.offeredMonthLicensePacks.forFree') + _.get(appuser, 'organization.plan.offeredMonthLicensePacks.thanksToSponsorship');
  const allCards = _.filter(paymentMethods, pm => !!pm.card);
  const allSepaDebits = _.filter(paymentMethods, pm => !!pm.sepaDebit);
  const [invalidTaxNumber, setInvalidTaxNumber] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [paymentMethod, updatePaymentMethod] = useImmer({
    value: null,
    remember: false,
    changed: false
  });
  const [company, updateCompany] = useImmer({
    value: (_.get(appuser, 'organization.plan.deals.0.thirdSubscriptionId') ? _.get(appuser, 'organization.company') : null) ?? true,
    changed: false
  });
  const [hasUserChosenSepaMode, updateHasUserChosenSepaMode] = useImmer({
    value: true,
    changed: false
  });
  const [name, updateName] = useImmer({
    value: _.get(appuser, 'organization.name') || '',
    changed: false
  });
  const [location, updateLocation] = useImmer({
    value: _.get(appuser, 'organization.location.address') || '',
    place: JSON.parse(_.get(appuser, 'organization.location.details') || 'null'),
    changed: false
  });
  const [firstname, updateFirstname] = useImmer({
    value: _.get(appuser, 'organization.referent.names.firstname') || _.get(appuser, 'profile.names.firstname') || '',
    changed: false
  });
  const [lastname, updateLastname] = useImmer({
    value: _.get(appuser, 'organization.referent.names.lastname') || _.get(appuser, 'profile.names.lastname') || '',
    changed: false
  });
  const [email, updateEmail] = useImmer({
    value: _.get(appuser, 'organization.referent.email') || _.get(appuser, 'account.email') || '',
    changed: false
  });
  const [phone, updatePhone] = useImmer({
    value: _.get(appuser, 'organization.referent.phone') || _.get(appuser, 'account.phone') || '',
    changed: false
  });
  const [sponsorCode, updateSponsorCode] = useImmer({
    value: '',
    changed: false,
    error: null
  });
  const [terms, updateTerms] = useImmer({
    value: false,
    changed: false
  });
  const [taxNumber, updateTaxNumber] = useImmer({
    value: _.get(appuser, 'organization.taxNumber.value', ''),
    changed: false
  });
  const [toBeLicensedMembers, updateToBeLicensedMembers] = useImmer({
    values: [],
    changed: false
  });
  const sepaModeFinally = hasUserChosenSepaMode.value && allSepaDebits.length === 0 && allCards.length === 0 || allSepaDebits.length > 0;
  const cardOptions = useRef({
    style: {
      base: {
        fontFamily: '"Mulish", sans-serif',
        fontSize: '1rem'
      }
    }
  }).current;
  let country = null;
  _.each(_.get(location, 'place.address_components') || [], c => {
    if (_.includes(c.types, 'country')) country = c.short_name;
  });
  const handleNameChange = async ev => {
    const value = ev.target.value;
    updateName(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleCompanyChange = async value => {
    updateCompany(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleSepaModeChange = async value => {
    updateHasUserChosenSepaMode(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleFirstnameChange = async ev => {
    const value = ev.target.value;
    updateFirstname(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleLastnameChange = async ev => {
    const value = ev.target.value;
    updateLastname(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleEmailChange = async ev => {
    let value = ev.target.value;
    if (value) value = value.trim();
    updateEmail(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handlePhoneChange = async ev => {
    const value = ev.target.value;
    updatePhone(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleSponsorCodeChange = async ev => {
    const value = ev.target.value;
    updateSponsorCode(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleLocationChange = place => {
    const address = _.get(place, 'formatted_address');
    if (!address) return;
    updateLocation(draft => {
      draft.place = address ? place : null;
      draft.value = address || null;
      draft.changed = true;
    });
  };
  const handleLocationInputChange = ev => {
    const value = ev.target.value;
    updateLocation(draft => {
      if (draft.value !== value) {
        draft.value = value;
        draft.place = null;
        draft.changed = true;
      }
    });
  };
  const handleTaxNumberChange = ev => {
    const value = ev.target.value;
    updateTaxNumber(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleToBeLicensedChange = values => {
    updateToBeLicensedMembers(draft => {
      draft.values = values;
      draft.changed = true;
    });
  };
  const handlePaymentMethodChange = async value => {
    updatePaymentMethod(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleTermsChange = async ev => {
    const value = ev.target.checked;
    updateTerms(draft => {
      draft.value = value;
      draft.changed = true;
    });
  };
  const handleSubmit = async ev => {
    if (ev) ev.preventDefault();
    if (!terms.value && showPaymentMethodForm()) {
      addFlash({
        type: 'warning',
        message: tr('front.checkout_form.submit.warning.agree_with_the_terms')
      });
      return;
    }
    if (validateForm()) {
      if (!waiting) {
        setWaiting(true);
        addFlash({
          id: 'ongoingPayment',
          type: 'info',
          message: tr('front.checkout_form.ajax.success.ongoing', {}, 'front_office-user'),
          closable: false,
          autoClose: false
        });
        const card = elements?.getElement(CardElement);
        const sepa = elements?.getElement(IbanElement);
        const createdPaymentMethod = !paymentMethod.value ? sepaModeFinally ? sepa && (await stripe.createPaymentMethod({
          type: 'sepa_debit',
          sepa_debit: sepa,
          billing_details: {
            name: `${firstname.value} ${lastname.value}`,
            email: email.value
          }
        })) : card && (await stripe.createPaymentMethod({
          type: 'card',
          card
        })) : null;
        const paymentMethodId = paymentMethod.value || allSepaDebits[0]?.id || _.get(createdPaymentMethod, 'paymentMethod.id') || null;
        if (!paymentMethodId && showPaymentMethodForm()) {
          addFlash({
            type: 'warning',
            message: tr('front.checkout_form.ajax.error.payment_method_problem', {}, 'front_office-user')
          });
          setWaiting(false);
          dismissFlash('ongoingPayment');
          return;
        }
        modal.block();
        modal.beLoading();
        if (showToBeLicensedSelect !== false) {
          const trait = 'maxLicensedWithPeriodicCloudyMembersCount';
          const licensedMemberIds = _.map(toBeLicensedMembers.values, member => _.get(member, 'id') || member);
          let defaultEUTaxSubType = 'EU_VAT';
          let defaultFRTaxSubType = 'FR_VAT';
          const body = {
            organizationId: appuser.organizationId,
            thirdPaymentMethodId: paymentMethodId,
            licensedMemberIds,
            company: company.value,
            ...(showSponsorCode && {
              sponsorCode: sponsorCode.value ? sponsorCode.value : null
            }),
            ...(showReferentForm() && {
              name: company.value ? name.value || null : null,
              referent: {
                email: email.value,
                names: {
                  firstname: firstname.value,
                  lastname: lastname.value
                },
                phone: phone.value
              },
              location: _.get(location, 'value') ? {
                address: _.get(location, 'place.formatted_address') || _.get(location, 'value'),
                details: _.get(location, 'place') ? JSON.stringify(_.get(location, 'place')) : null,
                source: _.get(location, 'place') ? 'GOOGLE' : null
              } : null,
              taxNumber: company.value && taxNumber.value ? {
                subType: country === 'FR' ? defaultFRTaxSubType : defaultEUTaxSubType,
                value: taxNumber.value
              } : null
            })
          };
          const waitForOrganizationPlanTraitChangeAndHandleSuccessIfItChanged = async () => {
            const aggregateId = await waitForAggregate({
              id: appuser.organization.id,
              domain: 'organization',
              maxTries: 24 * 60 * 60,
              secDelayBetweenTries: 1,
              queryAggregateBody: `plan { 
                                startTrialOnPayment
                                traits { 
                                    ${trait} { 
                                        value 
                                    } 
                                }
                                trialPeriod {
                                    startAt
                                    endAt
                                }
                            }`,
              validator: organization => !!(_.get(organization, `plan.traits.${trait}.value`) !== _.get(appuser, `organization.plan.traits.${trait}.value`) && (!_.get(organization, `plan.startTrialOnPayment`) || _.get(organization, `plan.trialPeriod.endAt`) && _.get(organization, `plan.trialPeriod.startAt`)))
            });
            if (_.includes(licensedMemberIds || [], appuser.id)) {
              await waitForAggregate({
                id: appuser.id,
                domain: 'user',
                maxTries: 24 * 60 * 60,
                secDelayBetweenTries: 1,
                queryAggregateBody: `account {
                                    license {
                                        id
                                    }
                                }`,
                validator: user => !!_.get(user, 'account.license.id')
              });
            }
            if (aggregateId) {
              const newTeam = await mutate(queryFrontOrganizationTeamApi(appuser.id));
              const newAppuser = await mutate(queryAppUserApi(appuser.id));
              updateRoot(draft => {
                draft.appuser = Factory.create('AppUser', newAppuser, false);
              });
              onSuccess(newAppuser, newTeam);
            }
          };
          const handleSuccess = async () => {
            await waitForOrganizationPlanTraitChangeAndHandleSuccessIfItChanged();
            dismissFlash('ongoingPayment');
            modal.unblock();
            modal.beLoading(false);
            modal.close();
          };
          const handleError = async (message, messageTtl = 10 * 1000, messageId = null, messageClosable = true, unlock = true) => {
            dismissFlash('ongoingPayment');
            setWaiting(false);
            let messageData = {
              type: 'warning',
              message,
              closable: messageClosable,
              autoClose: messageClosable
            };
            if (messageId) messageData.id = messageId;
            addFlash(messageData, messageTtl);
            if (unlock) {
              modal.unblock();
              modal.beLoading(false);
            }
          };
          const underWhitelabel = !window.areWeUnderDanimHost();
          const options = {
            method: 'POST',
            url: gen('write/organization/pay-license-pack'),
            data: body,
            underWhitelabel
          };
          await axios(options, {
            then: async data => {
              let {
                third_subscription,
                third_payment_intent,
                third_setup_intent
              } = data.data;
              const handlePaymentFailed = () => {
                const handleLinkClick = async () => {
                  dismissFlash('tryThirdPage');
                  addFlash({
                    id: 'nowWaitingForSuccessfulPayment',
                    type: 'warning',
                    message: tr('front.checkout_form.ajax.error.payment_failed.now_waiting_for_change_after_click', {}, 'front_office-user'),
                    closable: false,
                    autoClose: false
                  });
                  await waitForOrganizationPlanTraitChangeAndHandleSuccessIfItChanged();
                  dismissFlash('nowWaitingForSuccessfulPayment');
                  modal.close();
                };
                handleError(<span>
                                        {tr('front.checkout_form.ajax.error.payment_failed.before_link', {}, 'front_office-user')}{' '}
                                        <a style={{
                    textDecoration: 'underline'
                  }} target='_blank' href={_.get(third_subscription, 'latest_invoice.hosted_invoice_url')} onClick={handleLinkClick} rel='noreferrer'>
                                            {tr('front.checkout_form.ajax.error.payment_failed.inside_link', {}, 'front_office-user')}
                                        </a>
                                    </span>, -1, 'tryThirdPage', false, false);
              };
              if (third_payment_intent) {
                if (card && third_payment_intent?.status === 'requires_payment_method') handlePaymentFailed();else if (card && (third_payment_intent?.status === 'requires_action' || third_payment_intent?.status === 'requires_confirmation')) {
                  const res = await stripe.confirmCardPayment(third_payment_intent.client_secret, {
                    payment_method: paymentMethodId
                  });
                  if (res.error) {
                    if (_.get(res, 'error.payment_intent.status') === 'requires_payment_method') handlePaymentFailed();else {
                      await handleError(tr(`front.stripe.${md5(res.error.message)}`, {}, 'bridge-bridge') || tr('front.checkout_form.ajax.error.default', {}, 'front_office-user'));
                    }
                  }
                  await handleSuccess(data);
                } else if (sepa && third_payment_intent?.status === 'requires_payment_method') handlePaymentFailed();else if (sepa && third_payment_intent?.status === 'requires_action' || sepa && third_payment_intent?.status === 'requires_confirmation') {
                  const res = await stripe.confirmSepaDebitPayment(third_payment_intent.client_secret, {
                    payment_method: paymentMethodId
                  });
                  if (res.error) {
                    if (_.get(res, 'error.payment_intent.status') === 'requires_payment_method') handlePaymentFailed();else {
                      await handleError(tr(`front.stripe.${md5(res.error.message)}`, {}, 'bridge-bridge') || tr('front.checkout_form.ajax.error.default', {}, 'front_office-user'));
                    }
                  } else await handleSuccess(data);
                } else await handleSuccess(data);
              } else if (third_setup_intent) {
                if (card && third_setup_intent?.status === 'requires_payment_method') handlePaymentFailed();else if (sepa && third_setup_intent?.status === 'requires_payment_method') handlePaymentFailed();else if (card && third_setup_intent?.status === 'requires_action' && _.get(third_setup_intent, 'next_action.type') === 'use_stripe_sdk') {
                  const res = await stripe.confirmCardSetup(third_setup_intent.client_secret);
                  if (res.error) await handleError(tr(`front.stripe.${md5(res.error.message)}`, {}, 'bridge-bridge') || tr('front.checkout_form.ajax.error.default', {}, 'front_office-user'));else await handleSuccess(data);
                } else if (sepa && third_setup_intent?.status === 'requires_action' && _.get(third_setup_intent, 'next_action.type') === 'use_stripe_sdk') {
                  const res = await stripe.confirmSepaDebitSetup(third_setup_intent.client_secret);
                  if (res.error) await handleError(tr(`front.stripe.${md5(res.error.message)}`, {}, 'bridge-bridge') || tr('front.checkout_form.ajax.error.default', {}, 'front_office-user'));else await handleSuccess(data);
                } else await handleSuccess(data);
              } else await handleSuccess(data);
            },
            catch: error => {
              if (error.name === 'InvalidTaxNumber') setInvalidTaxNumber(true);
              handleError(tr(`front.stripe.${md5(error.message)}`, {}, 'bridge-bridge') || tr(`front.checkout_form.ajax.error.${_.snakeCase(error.name)}`, {}, 'front_office-user') || tr('front.checkout_form.ajax.error.default', {}, 'front_office-user'));
            }
          });
        }
      }
    }
  };
  const showReferentForm = () => withReferentInfo || invalidTaxNumber || !_.get(appuser, 'organization.referent') || !_.get(appuser, 'organization.location') || !validateReferentForm(false);
  const showPaymentMethodForm = () => !appuser?.hasOrganizationPlanThirdlyActiveDeal();
  const showSummary = () => !_.get(appuser, 'account.license') && appuser?.hasOrganizationPlanThirdlyActiveDeal() || showPaymentMethodForm();
  const showSponsorCode = !appuser.hasOrganizationPlanThirdlyActiveDeal() && !_.get(appuser, 'organization.sponsorId');

  /**
   * Returns bool: true if no validation error was detected
   */
  const validateReferentForm = (raiseErrors = true) => {
    let noProblem = true;
    let filled = {};
    let valid = {};
    filled.firstname = !!firstname.value;
    if (raiseErrors) {
      const firstname = document.getElementsByName('firstname')[0];
      firstname && !isInWindow(firstname) && !filled.firstname && firstname.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'end'
      });
      updateFirstname(draft => {
        draft.error = filled.firstname ? null : ['front.checkout_form.firstname.error.empty', {}, 'front_office-user'];
      });
    }
    noProblem *= filled.firstname;
    filled.lastname = !!lastname.value;
    if (raiseErrors) {
      const lastname = document.getElementsByName('lastname')[0];
      lastname && !isInWindow(lastname) && !lastname.value && lastname.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'end'
      });
      updateLastname(draft => {
        draft.error = filled.lastname ? null : ['front.checkout_form.lastname.error.empty', {}, 'front_office-user'];
      });
    }
    noProblem *= filled.lastname;
    filled.email = !!email.value;
    if (raiseErrors) {
      const email = document.getElementsByName('email')[0];
      email && !isInWindow(email) && !filled.email && email.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'end'
      });
      updateEmail(draft => {
        draft.error = filled.email ? null : ['front.checkout_form.email.error.empty', {}, 'front_office-user'];
      });
    }
    noProblem *= filled.email;
    if (filled.email) {
      valid.email = isEmail(email.value);
      if (raiseErrors) updateEmail(draft => {
        draft.error = valid.email ? null : ['front.checkout_form.email.error.invalid', {}, 'front_office-user'];
      });
      noProblem *= valid.email;
    }
    filled.phone = !!phone.value;
    if (raiseErrors) {
      const phone = document.getElementsByName('phone')[0];
      phone && !isInWindow(phone) && !phone.value && phone.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'end'
      });
      updatePhone(draft => {
        draft.error = filled.phone ? null : ['front.checkout_form.phone.error.empty', {}, 'front_office-user'];
      });
    }
    noProblem *= filled.phone;
    filled.location = !!location.place;
    if (raiseErrors) updateLocation(draft => {
      draft.error = filled.location ? null : ['front.checkout_form.location.error.not_from_google', {}, 'front_office-user'];
    });
    noProblem *= filled.location;
    if (company.value) {
      filled.name = !!name.value;
      if (raiseErrors) updateName(draft => {
        draft.error = filled.name ? null : ['front.checkout_form.name.error.empty', {}, 'front_office-user'];
      });
      noProblem *= filled.name;
    }
    filled.taxNumber = !!taxNumber.value;
    if (raiseErrors) {
      updateTaxNumber(draft => {
        draft.error = null;
      });
    }
    return noProblem;
  };

  /**
   * Returns bool: true if no validation error was detected
   */
  const validateForm = (raiseErrors = true) => {
    let noProblem = true;
    let filled = {};
    noProblem *= validateReferentForm(raiseErrors);
    if (showToBeLicensedSelect) {
      filled.toBeLicensed = !!toBeLicensedMembers.values.length;
      if (raiseErrors) updateToBeLicensedMembers(draft => {
        draft.error = filled.toBeLicensed ? null : ['front.checkout_form.to_be_licensed.error.empty', {}, 'front_office-user'];
      });
      noProblem *= filled.toBeLicensed;
      filled.toBeLicensed = !!toBeLicensedMembers.values.length && toBeLicensedMembers.values.length <= maxUsersToBeLicensed;
      if (raiseErrors) updateToBeLicensedMembers(draft => {
        draft.error = filled.toBeLicensed ? null : ['front.checkout_form.to_be_licensed.error.too_many', {
          max: maxUsersToBeLicensed
        }, 'front_office-user'];
      });
      noProblem *= filled.toBeLicensed;
    }
    return noProblem;
  };
  const handleCheckSponsorCode = async () => {
    if (showSponsorCode && sponsorCode.value?.length > 0 && sponsorCode.changed) {
      const body = {
        query: `
                query {
                    organization (sponsorCode: "${sponsorCode.value?.length > 0 ? escape(sponsorCode.value) : null}") {
                        __typename
                    }
                }`
      };
      apiRequest(body.query).then(data => {
        if (data.data.organization === null) {
          updateSponsorCode(draft => {
            draft.error = 'front.checkout_form.sponsor_code.error.invalid';
          });
        } else {
          updateSponsorCode(draft => {
            draft.error = null;
          });
        }
      }).catch(error => {
        updateSponsorCode(draft => {
          draft.error = null;
        });
        Sentry.captureException(error);
        console.log(error);
      });
    } else {
      updateSponsorCode(draft => {
        draft.error = null;
      });
    }
  };
  return <form className='user:checkoutForm' onSubmit={handleSubmit}>
            {showReferentForm() === true && <div className='-referent'>
                    <DanimInput customAttributes={{
        root: {
          className: '-firstname'
        }
      }} name='firstname' label={tr('front.checkout_form.firstname.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.firstname.placeholder', {}, 'front_office-user')} value={firstname.value} error={tr(...(firstname.error || []))} type='text' onChange={handleFirstnameChange} />
                    <DanimInput customAttributes={{
        root: {
          className: '-lastname'
        }
      }} name='lastname' label={tr('front.checkout_form.lastname.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.lastname.placeholder', {}, 'front_office-user')} value={lastname.value} error={tr(...(lastname.error || []))} type='text' onChange={handleLastnameChange} />
                    <DanimInput customAttributes={{
        root: {
          className: '-email'
        }
      }} name='email' label={tr('front.checkout_form.email.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.email.placeholder', {}, 'front_office-user')} value={email.value} error={tr(...(email.error || []))} type='text' onChange={handleEmailChange} />
                    <DanimInput customAttributes={{
        root: {
          className: '-phone'
        }
      }} name='phone' label={tr('front.checkout_form.phone.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.phone.placeholder', {}, 'front_office-user')} value={phone.value} error={tr(...(phone.error || []))} type='text' onChange={handlePhoneChange} />
                    <GoogleInput customAttributes={{
        root: {
          className: '-location'
        }
      }} name='location' label={tr('front.checkout_form.location.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.location.placeholder', {}, 'front_office-user')} value={location.value} error={tr(...(location.error || []))} type='text' onInputChange={handleLocationInputChange} onChange={handleLocationChange} prepend={country ? (() => {
        const SvgComponent = dynamic(() => import(`@components/bridge/bridge/svg/${pascalCase(country)}`));
        return SvgComponent ? <SvgComponent className='-flag' /> : null;
      })() : null} />
                    <DanimSelect customAttributes={{
        root: {
          className: '-company'
        }
      }} name='company' label={tr('front.checkout_form.company.label', {}, 'front_office-user')} value={company.value} options={[{
        value: true,
        label: tr('front.checkout_form.company.options.company', {}, 'front_office-user')
      }, {
        value: false,
        label: tr('front.checkout_form.company.options.individual', {}, 'front_office-user')
      }]} onChange={handleCompanyChange} isClearable={false} />
                    {!!company.value && <DanimInput customAttributes={{
        root: {
          className: '-name'
        }
      }} name='name' label={tr('front.checkout_form.name.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.name.placeholder', {}, 'front_office-user')} value={name.value} error={tr(...(name.error || []))} type='text' onChange={handleNameChange} />}
                </div>}
            {showToBeLicensedSelect && <DanimSelect customAttributes={{
      root: {
        className: '-toBeLicensed'
      }
    }} label={tr('front.checkout_form.to_be_licensed.label', {}, 'front_office-user')} error={tr(...(toBeLicensedMembers.error || []))} onChange={handleToBeLicensedChange} isMulti isClearable getOptionValue={opt => _.get(opt, 'id') || opt} loadOptions={{
      params: value => ({
        query: `
                query {
                    optionUsers: users (
                        ${value ? `names: "${value}"` : ''}
                        inOrganization: "${appuser.organizationId}"
                        licensed: false
                    ) {
                        edges {
                            node {
                                id
                                profile { names { both } }
                            }
                        }
                    }
                    ${toBeLicensedMembers.values?.map((memberId, idx) => {
          memberId = memberId?.id || memberId;
          return `
                                valueUser${idx}: user (id: "${memberId}") {
                                    id
                                    profile { names { both } }
                                }
                            `;
        })}
                }
            `
      }),
      options: data => _.sortBy(_.uniqBy((() => {
        const buildOpt = node => ({
          value: node,
          id: _.get(node, 'id'),
          alien: _.get(node, 'id') !== appuser.id,
          label: `${_.get(node, 'profile.names.both')}${_.get(node, 'id') === appuser.id ? ` (${tr('front.you.lowercased', {}, 'bridge-bridge')})` : ''}`
        });
        let options = _.map(_.get(data, 'data.optionUsers.edges') || [], edge => buildOpt(_.get(edge, 'node')));
        _.forOwn(_.get(data, 'data') || {}, (valueUser, key) => {
          if (!key.startsWith('value')) return;
          options.push(buildOpt(valueUser));
        });
        return options;
      })(), 'id'), ['alien', 'id'])
    }} />}
            {showPaymentMethodForm() && <>
                    {paymentMethods.length === 0 && <DanimSelect customAttributes={{
        root: {
          className: '-sepaMode'
        }
      }} name='sepaMode' label={tr('front.checkout_form.payment_mode.label', {}, 'front_office-user')} value={hasUserChosenSepaMode.value} options={[{
        value: true,
        label: tr('front.checkout_form.payment_mode.options.sepa', {}, 'front_office-user')
      }, {
        value: false,
        label: tr('front.checkout_form.payment_mode.options.card', {}, 'front_office-user')
      }]} onChange={handleSepaModeChange} isClearable={false} />}
                    {!sepaModeFinally ? allCards.length >= 0 && <>
                                  <label className='-cardElementLabel'>
                                      {tr(`front.checkout_form.card.label.${allCards.length > 0 ? 'new' : 'default'}`, {}, 'front_office-user')}
                                  </label>
                                  <CardElement className='-cardElement' options={cardOptions} />

                                  {allCards.length > 0 && <DanimSelect customAttributes={{
          root: {
            className: '-paymentMethod'
          }
        }} options={allCards.map(method => {
          const BrandComponent = dynamic(() => import(`@components/bridge/bridge/svg/${pascalCase(method.card.brand.toLowerCase())}`));
          const FlagComponent = dynamic(() => import(`@components/bridge/bridge/svg/${pascalCase(method.card.country.toLowerCase())}`));
          return {
            value: method.id,
            label: <span>
                                                          <FlagComponent className='-flag' />
                                                          <BrandComponent className='-brand' />
                                                          <span className='-hider'>– – –</span>
                                                          <strong>{method.card.last4}</strong>
                                                          <em>
                                                              ({method.card.expMonth}/{method.card.expYear})
                                                          </em>
                                                      </span>
          };
        })} label={tr('front.checkout_form.card.or_existent', {}, 'front_office-user')} value={paymentMethod.value} onChange={handlePaymentMethodChange} />}
                              </> : allSepaDebits.length === 0 && <>
                                  <label className='-sepaElementLabel'>{tr('front.checkout_form.sepa.label')}</label>
                                  <IbanElement className='-sepaElement' options={{
          supportedCountries: ['SEPA'],
          placeholderCountry: country ?? 'FR',
          style: {
            base: {
              fontFamily: '"Mulish", sans-serif',
              fontSize: '1rem'
            }
          }
        }} />
                                  <div className='-sepaWarning'>
                                      {tr('front.billing_page.forms.general.sepa.disclaimer.part_1', {}, 'front_office-organization')}
                                      <br />
                                      {tr('front.billing_page.forms.general.sepa.disclaimer.part_2', {}, 'front_office-organization')}
                                  </div>
                              </>}
                    <Accordion style={{
        marginTop: '1rem',
        marginBottom: '0.6rem',
        borderRadius: '0.25rem',
        border: '1px solid #ced4da',
        boxShadow: '0'
      }}>
                        <AccordionSummary expandIcon={<AddIcon />} style={{
          padding: '0px 12px'
        }}>
                            {tr('front.checkout_form.complementary_infos.label', {}, 'front_office-user')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {!!company.value && <DanimInput customAttributes={{
            root: {
              className: '-taxNumber'
            }
          }} name='taxNumber' label={tr('front.checkout_form.tax_number.label', {}, 'front_office-user')} placeholder={tr('front.checkout_form.tax_number.placeholder', {}, 'front_office-user')} value={taxNumber.value} error={tr(...(taxNumber.error || []))} type='text' onChange={handleTaxNumberChange} />}
                            {showSponsorCode && <FormControl sx={{
            mt: 1,
            mb: 1,
            width: '100%'
          }}>
                                    <InputLabel htmlFor='outlined-sponsor'>
                                        {tr('front.checkout_form.sponsor_code.label', {}, 'front_office-user')}
                                    </InputLabel>
                                    <OutlinedInput id='outlined-sponsor' type='text' name='sponsorCode' label={tr('front.checkout_form.sponsor_code.label', {}, 'front_office-user')} value={sponsorCode.value} error={!!sponsorCode.error} onChange={handleSponsorCodeChange} onMouseLeave={handleCheckSponsorCode} onTouchMove={handleCheckSponsorCode} />
                                    {!!sponsorCode.error && <FormHelperText sx={{
              mb: 1
            }} error>
                                            {tr(sponsorCode.error)}
                                        </FormHelperText>}
                                </FormControl>}
                        </AccordionDetails>
                    </Accordion>
                    <FormGroup check className='-terms'>
                        <Label check>
                            <CustomInput name='terms' id='terms' type='checkbox' checked={terms.value || false} onChange={handleTermsChange} />{' '}
                            <span dangerouslySetInnerHTML={{
            __html: tr('front.checkout_form.terms.label', {
              link: `https://danim.com/${!appuser || appuser.isAdvancedDirector() && !appuser.isGrantedRole('EDITOR') ? 'terms/creators' : 'terms'}`
            }, 'front_office-user')
          }} />
                        </Label>
                    </FormGroup>
                </>}
            {showSummary() === true && <DealSummary offeredMonthLicensePacksTotalCount={offeredMonthLicensePacksTotalCount} deal={_.get(appuser, 'organization.plan.deals.0')} nextDeal={_.get(appuser, 'organization.plan.deals.1.thirdProductId') ? _.get(appuser, 'organization.plan.deals.1') : _.get(appuser, 'organization.plan.deals.0')} infoOnPrices={infoOnPrices} infoOnPacks={infoOnPacks} />}
            <WaitingButton customAttributes={{
      root: {
        className: '-submit'
      }
    }} onClick={handleSubmit} waiting={waiting} text={tr('front.checkout_form.submit.text', {}, 'front_office-user')} />
            {blockExit && <Link href={`/user/${appuser.id}`}>
                    <a className='-redirect'>{tr('front.checkout_form.or_go_elsewhere', {}, 'front_office-user')}</a>
                </Link>}
        </form>;
});
export default CheckoutForm;