function queryVideoNew(id, prefillId) {
    return `query queryVideoNew {
                            ${
                                prefillId
                                    ? `
                video (id: "${prefillId}") {
                    name
                    description
                    workspaceId
                    templateData
                }`
                                    : ''
                            }
                videoPayloadNewVideo:template (shortId: "${id}") {
                    id
                    name
                    config
                    dynamic
                    suggestedThirdMusicIds
                    generationCost
                    readyForCloudUse (byHeredity: true)
                    forCloudGenerations
                    libraryId
                    organizationId
                    workspaceId
                    fontStore (case: SNAKE)
                    pictorial
                    generationVariables {
                        sourceMap
                        data
                    }
                    organization {
                        fontStore (case: SNAKE)
                    }
                    workspace {
                        fontStore (case: SNAKE)
                    }
                    mainSource {
                        id
                        config
                        hasBeenTestedAtLeastOnce
                        creatorId
                        byAdmin
                        wasValidBeforeTest
                        state
                        open
                    }
                    preview {
                        src (absolute: true)
                    }
                    thumbnail {
                        src (absolute: true)
                    }
                    parents (first: -1) {
                        edges {
                            node {
                                id
                                config
                            }
                        }
                    }
                }

        }`;
}

const queryVideoNewMock = () => 'FrontVideoVideoNew';

const queryFrontVideoNewApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryVideoNewMock : queryVideoNew;

export default queryFrontVideoNewApi;
