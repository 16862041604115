import __Base from '@components/Base';
import React from 'react';
class SnippetWaiter extends __Base {
  render = () => {
    return <article className='template:snippetWaiter'>
                <div className='-preview' />
                <div className='-footer'>
                    <div className='-name' />
                    <div className='-cost' />
                </div>
            </article>;
  };
}
export default SnippetWaiter;