import __Base from '@components/Base';
import React from 'react';
import PropTypes from 'prop-types';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import VideoFile from '@components/bridge/bridge/VideoFile';
import Link from 'next/link';
import _ from 'lodash';
import clsx from 'clsx';
import stringToColour from '@utils/lodash/stringToColour';
import { preload } from 'swr';
import { apiSwrRequest } from '@services/http/client';
import queryFrontVideoNewApi from '@queries/api/office/front-office/video/queryVideoNew';
class Snippet extends __Base {
  constructor(props) {
    super(props);
    this.state = {
      preloader: null
    };
  }
  handleConfigClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation() & this.props.onConfigClick(this.props.template.id);
  };
  handleLockClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation() & this.props.onLockClick(this.props.template.id);
  };
  handleMouseOff = () => {
    clearTimeout(this.state.loader);
    this.props.onMouseOff(this.props.template.id);
  };
  handleMouseOn = () => {
    this.updateState({
      loader: setTimeout(() => {
        preload(queryFrontVideoNewApi(this.props.template.shortId), apiSwrRequest);
      }, 1500)
    });
    this.props.onMouseOn(this.props.template.id);
  };
  handleRemoveClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation() & this.props.onRemoveClick(this.props.template.id);
  };
  handleUnlockClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation() & this.props.onUnlockClick(this.props.template.id);
  };
  render = () => {
    const {
      playing,
      mute,
      onVolumeClick,
      template,
      appuser,
      workspace
    } = this.props;
    if (!_.get(template, 'shortId')) return null;
    const locked = _.get(template, 'private.selfIncluded') === false && _.get(template, 'private.hereditary') === true;
    const enterable = !appuser ||
    // library
    appuser.isOrganizationDirector() || appuser.isGrantedOrganizationBadge({
      workspaceId: _.get(workspace, 'id'),
      badge: 'MANAGER'
    });
    return <Link href={enterable ? `/video/new?templateShortId=${_.get(template, 'shortId')}` : ''} prefetch={false}>
                <a data-test-id={`template-${this.props.template.id}`} className={clsx('template:snippet', locked && '/locked', enterable && '/enterable')} onMouseEnter={probe.desktop ? this.handleMouseOn : null} onMouseLeave={probe.desktop ? this.handleMouseOff : null}>
                    <VideoFile ref={obj => this.video = obj} mute={mute} loop customAttributes={{
          root: {
            className: '-preview'
          }
        }} videoOnlyWhenNeeded={false} src={_.get(template, 'preview.src')} poster={_.get(template, 'thumbnail.src')} controls={!probe.desktop} playing={playing} onMouseOn={probe.desktop ? null : this.handleMouseOn} onMouseOff={probe.desktop ? null : this.handleMouseOff} onVolumeClick={onVolumeClick} isPictorial={!!_.get(template, 'pictorial')} />
                    <div className='-footer'>
                        <div className='-name'>{_.get(template, 'name')}</div>
                        {workspace === null && !!_.get(template, 'workspace.id') === true && <span className='workspace:tag' style={{
            backgroundColor: stringToColour(_.get(template, 'workspace.id'), 0xb4)
          }}>
                                {_.get(template, 'workspace.name') || 'Anonymous'}
                            </span>}
                    </div>
                    {probe.desktop === true && <div className='-buttons'>
                            {enterable === true && <TooltipBox customAttributes={{
            root: {
              className: '-rocket'
            }
          }} caption={tr('front.snippet.generate_button', {}, 'front_office-template')} style={{
            right: 'calc(100% + 1rem)',
            top: '50%',
            transform: 'translateY(-60%)'
          }}>
                                    <FontAwesomeIcon icon={faRocket} width={16} />
                                </TooltipBox>}
                            {/* { ( */}
                            {/*    !!appuser */}
                            {/*    && ( */}
                            {/*        appuser.isOrganizationDirector() */}
                            {/*        // || appuser.isGrantedOrganizationBadge({workspaceId: _.get(workspace, 'id'), badge: 'CREATOR'}) */}
                            {/*    ) */}
                            {/*    && !_.get(template, 'libraryId') */}
                            {/*    && !!_.get(template, 'organizationId') */}
                            {/* ) === true && */}
                            {/* <App.TooltipBox */}
                            {/*    customAttributes={{ */}
                            {/*        root: { */}
                            {/*            className: '-config', */}
                            {/*            onClick: this.handleConfigClick, */}
                            {/*            'data-id': _.get(template, 'id'), */}
                            {/*        } */}
                            {/*    }} */}
                            {/*    caption={tr('front.snippet.buttons.configure', {}, 'front_office-template')} */}
                            {/*    style={{right: 'calc(100% + 1rem)', top: '50%', transform: 'translateY(-50%)'}} */}
                            {/* > */}
                            {/*    <Fa.Icon icon="cog" /> */}
                            {/* </App.TooltipBox> */}
                            {/* } */}
                            {/* {(locked === false &&
                                !!appuser &&
                                // (appuser.isOrganizationDirector() ||
                                //     appuser.isGrantedOrganizationBadge({
                                //         workspaceId: _.get(workspace, 'id'),
                                //         badge: 'CREATOR'
                                //     })) &&
                                appuser.isOrganizationDirector() &&
                                !_.get(template, 'libraryId') &&
                                !!_.get(template, 'organizationId')) === true && (
                                <TooltipBox
                                    customAttributes={{
                                        root: {
                                            className: '-lock',
                                            onClick: this.handleLockClick,
                                            'data-id': _.get(template, 'id')
                                        }
                                    }}
                                    caption={
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: tr(
                                                    `front.snippet.buttons.lock.${workspace ? 'this_workspace' : 'all_workspaces'}`,
                                                    {},
                                                    'front_office-template'
                                                )
                                            }}
                                        />
                                    }
                                    style={{ right: 'calc(100% + 1rem)', top: '50%', transform: 'translateY(-50%)' }}
                                >
                                    <FontAwesomeIcon icon={faLock} />
                                </TooltipBox>
                             ) */}
                            {/* (locked === true &&
                                !!appuser &&
                                // (appuser.isOrganizationDirector() ||
                                //     appuser.isGrantedOrganizationBadge({
                                //         workspaceId: _.get(workspace, 'id'),
                                //         badge: 'CREATOR'
                                //     })) &&
                                appuser.isOrganizationDirector() &&
                                !_.get(template, 'libraryId') &&
                                !!_.get(template, 'organizationId')) === true && (
                                <TooltipBox
                                    customAttributes={{
                                        root: {
                                            className: '-unlock',
                                            onClick: this.handleUnlockClick,
                                            'data-id': _.get(template, 'id')
                                        }
                                    }}
                                    caption={tr('front.snippet.buttons.unlock', {}, 'front_office-template')}
                                    style={{ right: 'calc(100% + 1rem)', top: '50%', transform: 'translateY(-50%)' }}
                                >
                                    <FontAwesomeIcon icon={faUnlock} />
                                </TooltipBox>
                             ) */}
                            {/* { ( */}
                            {/*    !!appuser */}
                            {/*    && ( */}
                            {/*        appuser.isOrganizationDirector() */}
                            {/*        // || appuser.isGrantedOrganizationBadge({workspaceId: _.get(workspace, 'id'), badge: 'CREATOR'}) */}
                            {/*    ) */}
                            {/*    && !_.get(template, 'libraryId') */}
                            {/*    && !!_.get(template, 'organizationId') */}
                            {/* ) === true && */}
                            {/* <App.TooltipBox */}
                            {/*    customAttributes={{ */}
                            {/*        root: { */}
                            {/*            className: '-remove', */}
                            {/*            onClick: this.handleRemoveClick, */}
                            {/*            'data-id': _.get(template, 'id'), */}
                            {/*        } */}
                            {/*    }} */}
                            {/*    caption={<span dangerouslySetInnerHTML={{__html: tr(`front.snippet.buttons.remove.${workspace ? 'this_workspace' : 'all_workspaces'}`, {}, 'front_office-template')}} />} */}
                            {/*    style={{right: 'calc(100% + 1rem)', top: '50%', transform: 'translateY(-50%)'}} */}
                            {/* > */}
                            {/*    <Fa.Icon icon="trash-alt" /> */}
                            {/* </App.TooltipBox> */}
                            {/* } */}
                        </div>}
                    {locked === true && <span className='-locked' dangerouslySetInnerHTML={{
          __html: tr(`front.snippet.locked.${workspace ? 'this_workspace' : 'all_workspaces'}`, {}, 'front_office-template')
        }} />}
                </a>
            </Link>;
  };
}
Snippet.defaultProps = {
  mute: true,
  onLockClick: templateId => null,
  onMouseOff: templateId => null,
  onMouseOn: templateId => null,
  onRemoveClick: templateId => null,
  onUnlockClick: templateId => null,
  onVolumeClick: () => null,
  playing: true
};
Snippet.propTypes = {
  // required
  template: PropTypes.object.isRequired,
  // optional
  mute: PropTypes.bool,
  onLockClick: PropTypes.func,
  onMouseOff: PropTypes.func,
  onMouseOn: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onUnlockClick: PropTypes.func,
  onVolumeClick: PropTypes.func,
  playing: PropTypes.bool
};
export default Snippet;